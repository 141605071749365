import React, { Children, cloneElement, createContext, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";


const FormContext = createContext();

const Form = ({ onSubmit, children, ...props }) => {

    const [formErrors, setFormErrors] = useState({});

    let counter = 1;

    const recursivelyCloneChildren = (children) => {
        return Children.map(children, (child, index) => {
            if (!React.isValidElement(child)) {
                return child;
            }
            
            // Pass formErrors, setFormErrors, and position to all children
            const childProps = {
                formErrors,
                setFormErrors,
                position: counter, // Increment position for each child
                positionFactor: counter * 100,
            };
            
            // Check if the child has type === "submit"
            if (child?.props?.type === "submit") {
                childProps.disabled = child?.props?.disabled ? (child?.props?.disabled || Object.values(formErrors).some(error => error)) : Object.values(formErrors).some(error => error);
            }
            
            counter = counter + 1;
            
            // If the child has children, recursively clone them
            if (child?.props?.children) {
                childProps.children = recursivelyCloneChildren(child?.props?.children);
            }

            return cloneElement(child, childProps);
        });
    };

    const formContextData = useMemo(() => ({ formErrors, setFormErrors, formPosition: children?.length }), []);

    return (
        <FormContext.Provider value={formContextData}>
            <form
                onSubmit={onSubmit}
                {...props}
            >
                {recursivelyCloneChildren(children)}
            </form>
        </FormContext.Provider>
    );

}

Form.propTypes = {
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func,
};

export const useFormContext = () => useContext(FormContext);

export default Form;