import React from 'react'
import Text from 'atoms/Text'
import Card from 'atoms/Card'
import Image from 'next/image'
import LinkAnchor from 'atoms/LinkAnchor'
import IconRightArrow from 'atoms/SvgIcons/IconRightArrow.svg'
import BodyForModal from 'molecules/Modals/BodyForModal'

const ThankYouCard = ({ className = '', heading = 'Thank You!', thankYouMsg, onClose = () => { } }) => {
    return (
        <Card className={`${className}`} >
            <BodyForModal type="bodyType1" className="px-4 pt-0 pb-16 md:pt-10 md:pb-20 relative overflow-y-auto flex-grow object-contain items-center justify-center">
                <div className='flex justify-center w-full mb-5'>
                    <div className="image-container relative w-full h-full max-w-20 max-h-20">
                        <Image src='/images/greenLike.svg' alt='' layout="fill" className={'image'} />
                    </div>
                </div>

                <div className="w-full flex justify-center md:justify-start flex-col items-center md:items-start mx-auto md:max-w-[590px] p-4 pt-0 text-gray-200">
                    <Text className='text-gray-900 font-bold font-Montserrat text-1.5xl text-center w-full'>{heading}</Text>
                    <Text type='subtitle' className='text-gray-600 md:text-base font-Open-Sans md:font-normal mt-3 md:mt-5 text-base md:max-w-[360px] text-center mx-auto'>{thankYouMsg}</Text>
                </div>
                <div className='flex justify-center mt-1 md:mt-3'>
                    <LinkAnchor href={'/my-appointments?page=1'} onClick={onClose} dataAutomation='thankyou-request-appointment' className='text-primary1-500 hover:underline font-semibold text-sm mx-auto inline-block'>
                        <span className='whitespace-nowrap'>Click here to view your appointment</span> <IconRightArrow className='inline-block fill-none ml-1.5' size={18} />
                    </LinkAnchor>
                </div>

            </BodyForModal>
        </Card >
    )
}

export default ThankYouCard