

export const heightUnitOptions = [
    { value: 'cm', label: 'Height in CM' },
    { value: 'ft-in', label: 'Height in Feet' },
];


export const APPOINTMENT_FLOW = {
    UNPAID: 0,
    PAID: 1
}

export const APPOINTMENT_GA_CATEGORY = {
    PLP: 'PLP',
    PDP: 'PDP',
    MICROSITE:'Microsite',
    TREATMENT: 'Treatment',
    SERVICES: 'Services',
    INDIVIDUAL_HOMEPAGE: 'Individual_Homepage',
    FIND_DOCTOR: 'Find Doctor',
    DOCTOR_WEBPAGE: 'Doctor Webpage',
    BLOG_DETAILS: 'Blog Detail',
    BLOG_LISTING: 'Blog Listing',
    MY_APPOINTMENT: 'My Appointment'
}